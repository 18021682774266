export class AEventDispatcher {
    constructor() {
        this.__listeners = {};
    }

    on(name, callback, prepend = false) {
        if(!this.__listeners[name]) {
            this.__listeners[name] = [];
        }

        if(prepend) {
            this.__listeners[name].unshift(callback);
        } else {
            this.__listeners[name].push(callback);
        }
    }
    off(name, callback) {
        if(this.__listeners[name]) {
            for (let i = 0, listener = null; listener = this.__listeners[name][i]; i++) {
                if(callback === listener) {
                    this.__listeners[name].splice(i, 1);
                    return;
                }
            }
        }
    }
    trigger(name) {
        let arg = Array.prototype.slice.call(arguments);
        arg.shift();

        if(this.__listeners[name] || this.__listeners['*']) {
            let listeners = (this.__listeners[name] || []).concat(this.__listeners['*'] || []);
            for (let i = 0, listener = null; listener = listeners[i]; i++) {
                if(arg && arg.length && typeof arg[0] === 'object') {
                    if(arg[0].bubbles && !arg[0].currentTarget) {
                        arg[0].currentTarget = this;
                        arg[0].eventName = name;
                    }
                }
                if(listener.apply(this, arg) === false) break;
            }
        }
    }
}