export function getContainSize (picWidth, picHeight, containerWidth, containerHeight) {
    let elemRatio = containerWidth / containerHeight,
        imgRatio = picWidth / picHeight;

    if ( imgRatio > elemRatio ) {

        let h = containerWidth/imgRatio;
        return {
            top : Math.round((containerHeight - h) / 2),
            width : containerWidth,
            height : h,
            left : 0
        };

    } else {

        let w = containerHeight * imgRatio;
        return {
            top : 0,
            width : w,
            height : containerHeight,
            left : Math.round((containerWidth - w) / 2)
        };
    }
}